import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import respond from "../../styles/abstracts/mediaqueries"

import CardImage from "../CardImage"
import decorativeTriangle from "../../images/home-play.svg"

const StyledHomeHero = styled.section`
  height: min-content;
  padding: 0;

  ${respond(
    "big-desktop",
    css`
      height: 60vh;
    `
  )}
  ${respond(
    "ipad-pro-11-land",
    css`
      height: 70vh;
    `
  )}
  ${respond(
    "ipad-pro-12-port",
    css`
      height: min-content;
    `
  )}
  ${respond(
    "phone-port",
    css`
      height: max-content;
      margin-bottom: 15rem;
    `
  )}

  .decoration-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;

    ${respond(
      "ipad-pro-11-land",
      css`
        height: 105%;
      `
    )}
    ${respond(
      "nexus-7-land",
      css`
        height: 120%;
      `
    )}
    ${respond(
      "ipad-pro-12-port",
      css`
        height: 55%;
      `
    )}
    ${respond(
      "tab-land",
      css`
        height: 110%;
      `
    )}
    ${respond(
      "nexus-7-land",
      css`
        height: 115%;
      `
    )}
    ${respond(
      "ipad-port",
      css`
        height: 66%;
      `
    )}
    ${respond(
      "iphone-12-pro-land",
      css`
        height: 160%;
      `
    )}
    ${respond(
      "ipad-pro-10-port",
      css`
        height: 60%;
      `
    )}
    ${respond(
      "ipad-port",
      css`
        height: 60%;
      `
    )}
    ${respond(
      "nexus-7",
      css`
        height: 70%;
      `
    )}
    
    ${respond(
      "iphone-5-land",
      css`
        height: 200%;
      `
    )}
    ${respond(
      "phone-port",
      css`
        height: 130%;
      `
    )}
    ${respond(
      "iphone-8-plus",
      css`
        height: 152%;
      `
    )}
    ${respond(
      "iphone-12",
      css`
        height: 142%;
      `
    )}
    ${respond(
      "small-phone",
      css`
        height: 190%;
      `
    )}
    ${respond(
      "iphone-5",
      css`
        height: 210%;
      `
    )}
    
  }

  .decoration {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .triangle {
    position: absolute;
    bottom: 5rem;
    left: 10rem;
    transform: ${({ randomCoordinates }) => css`
        translate(${randomCoordinates.x}px, ${randomCoordinates.y}px) rotate(${randomCoordinates.z}deg);
      `}
    transition: ${({ timerAmount }) => css`all ${timerAmount}s;`}

    ${respond(
      "big-desktop",
      css`
        left: 20rem;
        bottom: 10rem;
      `
    )}
    ${respond(
      "macbook-air",
      css`
        bottom: 0;
        left: 5rem;
        width: 20rem;
      `
    )}
    ${respond(
      "notebook",
      css`
        width: 15rem;
      `
    )}
    ${respond(
      "laptop-s",
      css`
        width: 17rem;
        left: 10rem;
      `
    )}
    ${respond(
      "ipad-pro-12.9-land",
      css`
        width: 20rem;
        bottom: 16rem;
        left: 12rem;
      `
    )}
    ${respond(
      "ipad-pro-12-port",
      css`
        width: 15rem;
        bottom: 18rem;
      `
    )}
    ${respond(
      "nexus-7-land",
      css`
        width: 12rem;
        bottom: 12rem;
      `
    )}
    ${respond(
      "phone-port",
      css`
        bottom: 35%;
        right: 5rem;
        left: initial;
      `
    )}
  }

  .top-container {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: center;
      margin-top: 12rem;
      justify-items: center;
      z-index: 2;

      ${respond(
        "ipad-pro-12-port",
        css`
          grid-template-columns: 2fr 15rem;
          margin-top: 10rem;
          justify-items: center;
          gap: 2rem;
        `
      )}
      ${respond(
        "nexus-7-land",
        css`
          grid-template-columns: 2fr 1fr;
          margin-top: 10rem;
        `
      )}
      ${respond(
        "iphone-12-pro-land",
        css`
          margin-top: 7rem;
        `
      )}
      ${respond(
        "phone-port",
        css`
          margin-top: 12rem;
          grid-template-columns: 1fr;
          gap: 0;
          padding: 0;
        `
      )}
      ${respond(
        "iphone-8-plus",
        css`
          margin-top: 8rem;
        `
      )}
      ${respond(
        "small-phone",
        css`
          margin-top: 8rem;
        `
      )}
  }

  .image-container {
      align-self: flex-start;
      transform: translateX(-100px);

      ${respond(
        "ipad-pro-12-port",
        css`
          transform: translateX(-10rem);
          width: 100%;
        `
      )}
      ${respond(
        "ipad-pro-11-land",
        css`
          transform: translateX(-10rem);
          width: 150%;
        `
      )}
      ${respond(
        "tab-land",
        css`
          width: 180%;
        `
      )}
      ${respond(
        "nexus-7-land",
        css`
          transform: translateX(0);
          width: 100%;
        `
      )}
      ${respond(
        "phone-port",
        css`
          grid-row: 2 / 3;
          width: 60%;
        `
      )}
  }

  .title-container {
      width: max-content;
      justify-self: flex-end;
      transform: translateX(20px);

      ${respond(
        "nexus-7",
        css`
          width: 80%;
          justify-self: flex-start;
        `
      )}
      ${respond(
        "iphone-12-land",
        css`
          justify-self: flex-start;
        `
      )}
      ${respond(
        "iphone-12-land",
        css`
          width: 100%;
        `
      )}
      ${respond(
        "phone-port",
        css`
          margin: 0;
          margin-top: 0 !important;
          text-align: center;
          justify-self: center;
          width: 100% !important;
        `
      )}
      ${respond(
        "macbook-air",
        css`
          align-self: flex-start;
          margin-top: 5rem;
        `
      )}

      h1 {
        ${respond(
          "nexus-7",
          css`
            font-size: 2.6rem;
          `
        )}
        ${respond(
          "iphone-12-land",
          css`
            font-size: 2rem;
          `
        )}
        ${respond(
          "phone-port",
          css`
            margin-top: 0;
            font-size: 1.8rem;
            text-align: center;
            transform: translateX(-20px);
          `
        )}
      }

      h5 {
          width: 80%;

          ${respond(
            "ipad-pro-12-port",
            css`
              font-size: 2.2rem;
              width: 80%;
            `
          )}
          ${respond(
            "nexus-7-land",
            css`
              font-size: 1.6rem;
              width: 80%;
            `
          )}
          ${respond(
            "nexus-7",
            css`
              width: 100%;
            `
          )}
          ${respond(
            "iphone-12-land",
            css`
              width: 100%;
              font-size: 1.2rem;
            `
          )}
          ${respond(
            "iphone-12-pro-land",
            css`
              width: 90%;
              font-size: 1.6rem;
            `
          )}
      }
  }

  .name, .name-subtitle {
    ${respond(
      "phone-port",
      css`
        font-family: "Cinzel", serif;
        font-size: 3rem;
        line-height: 1.3;
        font-weight: 300;
      `
    )}
    
  }
`

const HomeHero = () => {
  const [currentTimer, setCurrentTimer] = useState(null)
  const [randomCoordinates, setRandomCoordinates] = useState({
    x: Math.floor(Math.random() * 50),
    y: Math.floor(Math.random() * 50),
    z: Math.floor(Math.random() * 180),
  })
  const [timerAmount, setTimerAmount] = useState(0)

  function createRandomCoordinates(timerAmount) {
    const timer = setTimeout(() => {
      setRandomCoordinates({
        x: Math.floor(Math.random() * 50),
        y: Math.floor(Math.random() * 50),
        z: Math.floor(Math.random() * 180),
      })
      clearTimeout(timer)
    }, timerAmount)
    setCurrentTimer(timer)
  }

  useEffect(() => {
    // START ANIMATION ON THE FIRST ITERATION
    createRandomCoordinates(0)
    setTimerAmount(5)

    return () => clearTimeout(currentTimer)
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    // CONTINUE THE ANIMATION LOOP IN EVERY SUBSEQUENT ITERATION
    createRandomCoordinates(5000)
    setTimerAmount(5)

    return () => {
      clearTimeout(currentTimer)
    }
    // eslint-disable-next-line
  }, [randomCoordinates])

  return (
    <StyledHomeHero
      randomCoordinates={randomCoordinates}
      timerAmount={timerAmount}
    >
      <div className="container top-container">
        <div className="title-container">
          <h1>
            <span className="name">GIOVANNI SETTI</span> <br />
            <span className="color-secondary name-subtitle">CONSULTING</span>
          </h1>
          <h5>Servizi di Pianificazione Patrimoniale Indipendente</h5>
        </div>
        <div className="image-container">
          <CardImage>
            <StaticImage
              src="../../images/giovanni.png"
              alt="Immagine di Giovanni Setti"
              placeholder="tracedSVG"
              layout="constrained"
              quality={100}
            ></StaticImage>
          </CardImage>
        </div>
      </div>

      <div className="decoration-container">
        <StaticImage
          src="../../images/hero-background.png"
          alt="decorative image"
          className="decoration"
        ></StaticImage>
        <img
          src={decorativeTriangle}
          alt="decorative triangle"
          className="triangle"
        />
      </div>
    </StyledHomeHero>
  )
}

export default HomeHero
