import React from "react"
import styled from "styled-components"

import Layout from "../layout/Layout"
import Seo from "../components/Seo"
import HomeHero from "../components/index/HomeHero"
import IndexCard from "../components/index/IndexCard"
import Form from "../components/Form"

const StyledIndex = styled.main``

const IndexPage = () => (
  <Layout>
    <Seo title="Giovanni Setti | Consulente Finanziario Indipendente" />
    <StyledIndex>
      <HomeHero />
      <IndexCard />
      <Form />
    </StyledIndex>
  </Layout>
)

export default IndexPage
