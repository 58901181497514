import React from "react"
import styled, { css } from "styled-components"
import respond from "../../styles/abstracts/mediaqueries"

import Card from "../Card"
import homeLi from "../../images/home-li.svg"

const StyledIndexCard = styled.div`
  margin-top: 15rem;
  margin-bottom: 20rem;
  padding: 0;

  ${respond(
    "iphone-12-mini-land",
    css`
      margin-top: 10rem;
    `
  )}
  ${respond(
    "phone-port",
    css`
      margin-bottom: 12rem;
    `
  )}

  ul {
    list-style: none;
    text-align: left;
  }

  .list-item {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    gap: 2rem;
  }

  .special-li-symbol {
  }
`

const IndexCard = () => {
  return (
    <StyledIndexCard className="container">
      <Card>
        <h2>CHI È GIOVANNI SETTI E PERCHÉ È LA PERSONA GIUSTA PER TE</h2>
        <ul>
          <div className="list-item">
            <img
              className="special-li-symbol"
              src={homeLi}
              alt="Voce della lista con dentro un check"
            />
            <li>
              Ha studiato Business Administration alla Queens University of
              Charlotte a Charlotte, North Carolina (USA);
            </li>
          </div>
          <div className="list-item">
            <img
              className="special-li-symbol"
              src={homeLi}
              alt="Voce della lista con dentro un check"
            />
            <li>
              Ha conseguito un Master in "Investment Management" all'università
              di Ginevra;
            </li>
          </div>
          <div className="list-item">
            <img
              className="special-li-symbol"
              src={homeLi}
              alt="Voce della lista con dentro un check"
            />
            <li>
              Ha conseguito un master in "Consulenza e Pianificazione
              Finanziaria Indipendente";
            </li>
          </div>
          <div className="list-item">
            <img
              className="special-li-symbol"
              src={homeLi}
              alt="Voce della lista con dentro un check"
            />
            <li>
              È specializzato in Finanza Sostenibile e strategie d'investimento
              ESG;
            </li>
          </div>
          <div className="list-item">
            <img
              className="special-li-symbol"
              src={homeLi}
              alt="Voce della lista con dentro un check"
            />
            <li>
              Gestisce patrimoni per un totale superiore ai 10 milioni di euro;
            </li>
          </div>
          <div className="list-item">
            <img
              className="special-li-symbol"
              src={homeLi}
              alt="Voce della lista con dentro un check"
            />
            <li>È l'autore di "Come Investire Partendo Da Zero";</li>
          </div>
          <div className="list-item">
            <img
              className="special-li-symbol"
              src={homeLi}
              alt="Voce della lista con dentro un check"
            />
            <li>
              Ha organizzato un importante "exit" della multinazionale
              "Tessilgraf" con la vendita della stessa a un fondo americano di
              Private Equity (HIG Capital).
            </li>
          </div>
        </ul>
      </Card>
    </StyledIndexCard>
  )
}

export default IndexCard
